import { mapGetters } from "vuex";

var useSelectables = {
  computed: {
    ...mapGetters([
      "costItemFamilies",
      "directSliceCostItems",
      "regions",
      "activities",
      "campaigns",
      "abstractCampaigns",
      "establishments",
    ]),
    selectableRegions() {
      return this.regions;
    },
    selectableActivities() {
      return this.activities;
    },
    selectableCampaigns() {
      return this.campaigns.map((campaign) => {
        return {
          ...campaign,
          startDate: this.abstractCampaigns
            .find(
              (abstractCampaigns) =>
                campaign.abstractCampaignId === abstractCampaigns.id
            )
            .startDate.toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
          endDate: this.abstractCampaigns
            .find(
              (abstractCampaigns) =>
                campaign.abstractCampaignId === abstractCampaigns.id
            )
            .endDate.toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
          displayName:
            this.abstractCampaigns.find(
              (abstractCampaigns) =>
                campaign.abstractCampaignId === abstractCampaigns.id
            ).name +
            "  - V" +
            campaign.version,
        };
      });
    },
  },
};

export default useSelectables;
